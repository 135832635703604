import "./error404.css";

const Error404 = () => {
  return (
    <main className="main">
      <h2 className="error__title">
        <span>ERROR 404</span>
        <p>Página no encontrada</p>
      </h2>
    </main>
  )
}

export default Error404