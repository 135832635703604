import React, { useEffect, useState } from 'react'
import Post from '../../components/Main/Posts'
import axios from 'axios'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import './home.css'
import { Helmet } from 'react-helmet'

const apiUrl = process.env.REACT_APP_API_URL

const Home = () => {
  const [posts, setPosts] = useState([])
  const [loading, setLoading] = useState(true)
  const category = useLocation().search

  const navigate = useNavigate()

  useEffect(() => {
    const fetchData = async () => {
      try {
        await axios
          .get(`${apiUrl}/posts/${category}`)
          .then((res) => setPosts(res.data))
        setLoading(false)
      } catch (err) {
        console.log(err)
        navigate('/error404')
        setLoading(false)
      }
    }
    fetchData()
  }, [category, navigate])

  const reversedPosts = [...posts].reverse()

  if (loading) {
    return (
      <main className='main'>
        <p>Cargando...</p>
      </main>
    )
  }

  if (reversedPosts.length === 0) {
    return (
      <main className='main'>
        <p>No hay publicaciones en esta categoría.</p>
      </main>
    )
  }

  return (
    <>
      <Helmet>
        <title>EMJ | Inicio</title>
        <meta
          name='description'
          content='Blog de noticias de tecnología, actualidad y consejos.'
        />
        <meta name="twitter:title" content='El Mundo de Jonathan' />
        <meta name="twitter:description" content='Un blog de noticias de tecnología, actualidad y consejos escrito por Jonathan Sánchez.' />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property='og:url' content='https://elmundodejonathan.es' />
        <meta property='og:title' content='El Mundo de Jonathan' />
        <meta property='og:description' content='Un blog de noticias de tecnología, actualidad y consejos escrito por Jonathan Sánchez.' />
      </Helmet>
      <main className='main'>
        <section className='main__posts'>
          {reversedPosts.map((post) => {
            return (
              <Link
                to={`/post/${post._id}`}
                className='main__post'
                key={post._id}
              >
                <Post
                  key={post._id}
                  title={post.title}
                  img={post.img}
                  content={post.content}
                  category={post.category}
                />
              </Link>
            )
          })}
        </section>
      </main>
    </>
  )
}

export default Home
